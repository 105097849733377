import React, {useContext} from "react"
import { Outlet } from "react-router-dom"
import VerticalNav, { VerticalNavEntries, VerticalNavEntry, VerticalNavSection, VerticalNavTitle } from "../common/slds/verticalNavigation/verticalNavigation"
import { useT } from "../common/i18n"
import { useAuthContext } from "../common/context/authContext"
import {FeatureContext} from "../common/context/featureContext";
import {Log} from "../common/log";

function ConfigurationPage() {
    const t = useT()
    const auth = useAuthContext()

    if (!auth.hasRole("admin")) {
        return null
    }

    const license = useContext(FeatureContext)

    const navigation = [
        {
            label: t("configuration.nav.applications", "Applications"),
            url: "/configuration/applications",
            requires: ["lobaro-device-gateway"]
        },
        {
            label: t("configuration.nav.hardware", "Hardware"),
            url: "/configuration/hardware",
            requires: ["lobaro-device-gateway"]
        },
        {
            label: t("configuration.nav.hardwareBattery", "Battery Status"),
            url: "/configuration/hardwareBattery",
            requires: ["lobaro-device-gateway"]
        },
        {
            label: t("configuration.nav.firmwareManagment", "Firmware"),
            url: "/configuration/firmware",
            requires: ["lobaro-device-gateway"]
        },
        {
            label: t("configuration.nav.activation-groups", "Activation Groups"),
            url: "/configuration/activationGroups",
            requires: ["lobaro-device-gateway"]
        },
        {
            label: t("configuration.nav.users", "Users"),
            url: "/configuration/users",
            requires: null
        },
        {
            label: t("configuration.nav.organisations", "Organisations"),
            url: "/configuration/organisations",
            requires: null
        },
        {
            label: t("configuration.nav.cron-log", "Cron Log"),
            url: "/configuration/cronLog",
            requires: null
        },
        {
            label: t("configuration.nav.system", "System"),
            url: "/configuration/admin",
            requires: null
        },
        {
            label: t("configuration.nav.admin-tokens", "Admin Tokens"),
            url: "/configuration/token",
            requires: null
        },
        {
            label: t("configuration.nav.license", "License"),
            url: "/configuration/license",
            requires: null
        },
        {
            label: t("configuration.nav.api-stats", "API Statistics"),
            url: "/configuration/api-stats",
            requires: null
        }
    ]

    const filtNavigation = navigation.filter(nav =>  Array.isArray(nav.requires) ? license.validateFeatures(...nav.requires) : true )
    Log.Debug("ConfigurationPage Navigation: ", filtNavigation)

    return <div className="slds-container--fluid">
        <div className="slds-grid">
            <div className="slds-col slds-no-flex">
                <VerticalNav>
                    <VerticalNavSection>
                        <VerticalNavTitle>Configuration</VerticalNavTitle>
                        <VerticalNavEntries>
                            {filtNavigation.map((nav, i) => {
                                    return <VerticalNavEntry key={i} to={nav.url}>{nav.label}</VerticalNavEntry>
                                },
                            )}
                        </VerticalNavEntries>
                    </VerticalNavSection>
                </VerticalNav>
            </div>
            <div className="slds-col">
                <Outlet />
            </div>
        </div>
    </div>
}

export default ConfigurationPage