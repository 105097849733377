import React, { useContext } from "react";
import { LoginButton } from "./loginbutton"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useAuthContext } from "../common/context/authContext"
import OrganisationSelector from "./organisationSelector"
import OrganisationBreadcrumbs from "./OrganisationBreadcrumbs"
import {useFeatureContext} from "../common/context/featureContext";
import {Icon} from "../common/slds/icons/icon";
import { OrganisationContext } from "../common/context/OrganisationContextProvider";
import { Log } from "../common/log";
import ErrorBoundary from "../common/ui/errorBoundary";

export const Header = (props) => {
    const auth = useAuthContext()

    const org = useContext(OrganisationContext)

    Log.Debug("Header", "org", org)

    const features = useFeatureContext()

    return (
        <ErrorBoundary>
            <div className="slds-global-header slds-grid slds-grid_align-spread">
                <div className="slds-global-header__item">
                    <div className="slds-grid" style={{ paddingLeft: "14px" }}>
                        {/*Logo Alignment with App Launcher Icon*/}
                        <Link to={props.logoLinkTo} className="">
                            {props.logo}
                        </Link>
                        <div className={"slds-p-left--medium"}>
                            <p className="slds-page-header__title slds-align-middle">{org?.name}</p>
                            {auth.hasRole("admin", "org-admin") && <OrganisationBreadcrumbs />}
                        </div>
                        {auth.hasRole("admin", "org-admin") && (
                            <div>
                                <OrganisationSelector />
                            </div>
                        )}
                    </div>
                </div>
                {features.devMode && (
                    <div className="slds-global-header__item">
                        <div className={"slds-box slds-box_xx-small"}>
                            <Icon name={"thunder"} size={"small"} colorVariant={"light"} />
                            <span className={"slds-p-left--small slds-text-color_weak"}>Developer Mode</span>
                        </div>
                    </div>
                )}
                <div className="slds-global-header__item">
                    <LoginButton />
                </div>
            </div>
        </ErrorBoundary>
    )
}

Header.propTypes = {
    "logo": PropTypes.object.isRequired,
    "logoLinkTo": PropTypes.string.isRequired,
}

export default Header