import React, { useContext, useEffect, useState } from "react"
import UserHomePage from "./userhome/userHomePage"
import WmbusApiModule from "./wmbusapi/wmbusApiModule"
import ConfigurationPage from "./configuration/configurationPage"
import HardwareActivationPage from "./hardwareActivation/hardwareActivationPage"
import { Navigate, Route, Routes } from "react-router-dom"
import { Header } from "./header/header"
import TopNavigation from "./header/topNavigation"
import { useAuthContext } from "./common/context/authContext"
import TopNavigationLink from "./header/topNavigationLink"
import TopNavigationDropdown from "./header/topNavigationDropdown"
import TopNavigationDropdownLink from "./header/topNavigationDropdownLink"
import HeaderOrganisationLogo from "./header/HeaderOrganisationLogo"
import { FrontendConfigContext } from "./common/context/frontendConfigContext"
import { DeviceTypesPage } from "./deviceTypes/deviceTypesPage"
import { DeviceTypesDetailPage } from "./deviceTypes/deviceTypesDetailPage"
import { useT } from "./common/i18n"
import DeviceDataPage from "./organisation/deviceData/deviceDataPage"
import DevicesPage from "./organisation/devices/devicesPage"
import DeviceDetailPage from "./organisation/devices/device/deviceDetailPage"
import { IntegrationsPage } from "./organisation/integrations/integrationPage"
import OrgConfigPage from "./organisation/config/orgConfigPage"
import LoginPage from "./auth/loginPage"
import RegistrationPage from "./auth/registrationPage"
import RestorePasswordPage from "./auth/RestorePasswordPage"
import MyAccountPage from "./auth/myAccountPage"
import ChangePasswordPage from "./auth/changePasswordPage"
import DeviceOverview from "./organisation/devices/device/deviceOverview"
import DeviceParsedData from "./organisation/devices/device/deviceParsedData"
import DeviceSensorData from "./organisation/devices/device/deviceSensorData"
import DeviceDownlinkData from "./organisation/devices/device/deviceDownlinkData"
import DeviceSettings from "./organisation/devices/device/deviceSettings"
import DeviceSecurity from "./organisation/devices/device/deviceSecurity"
import DeviceConfig from "./organisation/devices/device/deviceConfig"
import OrganisationSettingsPage from "./organisation/config/settings/OrganisationSettingsPage"
import WmbusConfigPage from "./organisation/config/wmbus/WmbusConfigPage"
import { OrganisationUsersPage } from "./organisation/config/users/OrganisationUsersPage"
import UserDetailPage from "./components/user/userDetailPage"
import NotFound from "./common/ui/notFound"
import DeviceTypeParsedData from "./organisation/deviceData/deviceTypeParsedData"
import { InputFilterTab } from "./organisation/integrations/inputFilterTab"
import RestApiTab from "./organisation/integrations/restApiTab"
import { HttpApiTab } from "./organisation/integrations/httpApiTab"
import { MqttApiTab } from "./organisation/integrations/mqttApiTab"
import SftpApiTab from "./organisation/integrations/sftpApiTab"
import { LorawanApiTab } from "./organisation/integrations/chirpstackApiTab"
import UpdateDeviceTypeDialog from "./deviceTypes/updateDeviceTypeDialog"
import DeviceTableConfigTab from "./deviceTypes/deviceTableConfigTab"
import DataTableConfigTab from "./deviceTypes/dataTableConfigTab"
import DeviceTypeParserForm from "./deviceTypes/deviceTypeParserForm"
import HardwareActivationTable from "./hardwareActivation/hardwareActivationTable"
import DeviceDataIndexPage from "./organisation/deviceData/deviceDataIndexPage"
import AppsPage from "./configuration/applications/appsPage"
import NewAppPage from "./configuration/applications/newAppPage"
import AppDetailPage from "./configuration/applications/appDetailPage"
import HardwareListPage from "./configuration/hardware/HardwareListPage"
import ImportHardwarePage from "./components/hardware/importHardwarePage"
import HardwareDetailPage from "./configuration/hardware/HardwareDetailPage"
import { HardwareBatteryPage } from "./configuration/hardware/HardwareBatteryPage"
import { FirmwareManagmentPage } from "./configuration/firmware/firmewareManagmentPage"
import ActivationGroupsListPage from "./configuration/activationGroups/activationGroupsListPage"
import ActivationGroupDetailPage from "./configuration/activationGroups/activationGroupDetailPage"
import UsersListPage from "./configuration/users/usersListPage"
import OrganisationsListPage from "./configuration/organisations/organisationListPage"
import OrganisationDetailPage from "./configuration/organisations/organisationDetailPage"
import CronLogPage from "./configuration/cronJobs/cronLogPage"
import AdminPage from "./configuration/admin/adminPage"
import { AdminTokenPage } from "./configuration/admin/adminTokenTab"
import ParserTab from "./wmbusapi/parserTab"
import ApiTab from "./wmbusapi/apiTab"
import { SubOrganisationPage } from "./organisation/config/subOrganisations/subOrgPage"
import InitialDevicesPage from "./organisation/devices/initialDevicesPage"
import ImportDeviceTypes from "./components/deviceType/importDeviceTypes"
import ErrorBoundary from "./common/ui/errorBoundary"
import KeyImportPage from "./organisation/config/wmbus/KeyImportPage"
import { LicensePage } from "./configuration/license/licensePage"
import { Log } from "./common/log"
import { useFeatureContext } from "./common/context/featureContext"
import { ApiStatisticsPage } from "./configuration/apiStatistics/apiStatisticsPage"
import { OrgApiStatisticsPage } from "./organisation/apiStatistics/orgApiStatistics"
import moment from "moment/moment"
import i18next from "i18next"

const NonAppModule = () => {
    const auth = useAuthContext()
    const [rerender, setRerender] = useState(1)
    auth.onChange(() => {
        setRerender(rerender + 1)
    })
    let allowRegistration = useContext(FrontendConfigContext)?.allowRegistration
    let t = useT()
    const frontendConfig = useContext(FrontendConfigContext)

    const licenseCfg = useFeatureContext()
    Log.Debug("Got feature context: ", licenseCfg)

    //set platform name
    if (frontendConfig?.brandingConfig?.platformName) {
        let pageTitle = frontendConfig.brandingConfig.platformName
        if (pageTitle !== "IoT Platform") {
            pageTitle += " - IoT Platform"
        }
        document.title = pageTitle
    }
    //set favicon
    if (frontendConfig?.brandingConfig?.favicon && document.getElementById("favicon")?.href != frontendConfig?.brandingConfig?.favicon) {
        document.getElementById("favicon").href = frontendConfig.brandingConfig.favicon
    }

    // update moment locale when language changes
    useEffect(() => {
        moment.locale(i18next.language)
        Log.Debug("nonAppModule.useEffect: moment locale updated", "currentLocale", i18next.language, "moment.locale", moment.locale())
    }, [t])

    // TODO: Grey out unavailable features?
    return (
        <>
            <Header logo={<HeaderOrganisationLogo />} logoLinkTo={"/"} />
            <TopNavigation
                moduleName={frontendConfig?.brandingConfig?.platformName ? frontendConfig?.brandingConfig.platformName : ""}
                secondary={
                    <>
                        {auth.isLoggedIn() && (
                            <>
                                {licenseCfg.validateFeatures("lobaro-device-gateway") && (
                                    <TopNavigationLink to={"/organisation/devices"} label={t("nav.devices", "Devices")} />
                                )}
                                {licenseCfg.validateFeatures("lobaro-device-gateway") && (
                                    <TopNavigationLink to={"/organisation/deviceData"} label={t("nav.data", "Data")} />
                                )}
                                {(licenseCfg.validateFeatures("lobaro-device-gateway") ||
                                    licenseCfg.validateFeatures("rest-api") ||
                                    licenseCfg.validateFeatures("wmbus-api")) && (
                                    <TopNavigationLink to={"/organisation/integrations"} label={t("nav.integrations", "Integrations")} />
                                )}
                                {licenseCfg.validateFeatures("lobaro-device-gateway") && (
                                    <TopNavigationLink to={"/deviceTypes"} label={t("nav.device-types", "Device Types")} />
                                )}
                            </>
                        )}
                        {(auth.hasRole("org-admin") || auth.hasRole("admin")) && (
                            <>
                                <TopNavigationLink to={"/organisation/config"} label={t("nav.organisation", "Organisation")} />
                            </>
                        )}
                        {auth.hasRole("admin") && (
                            <>
                                <TopNavigationLink to={"/configuration"} label={t("nav.configuration", "Configuration")} />
                            </>
                        )}
                        <ToolsTopNavigationDropdown />
                    </>
                }
            />
            <Routes>
                <Route index element={<UserHomePage />} />
                <Route path={"/auth"}>
                    <Route path={"login"} element={<LoginPage />} />
                    {allowRegistration ? <Route path={"register"} element={<RegistrationPage />} /> : null}
                    <Route path={"restore-password"} element={<RestorePasswordPage />} />
                    <Route path={"my-account"} element={<MyAccountPage />} />
                    <Route path={`changePassword/:userId`} element={<ChangePasswordPage />} />
                </Route>
                <Route path={"/wmbus"} element={<WmbusApiModule />}>
                    <Route index element={<Navigate to={"parser"} />} />
                    <Route path={"parser"} element={<ParserTab />} />
                    <Route path={"api"} element={<ApiTab />} />
                </Route>
                <Route path={"/organisation"}>
                    <Route path={"deviceData"} element={<DeviceDataPage />}>
                        <Route index element={<DeviceDataIndexPage />} />
                        <Route
                            path={":deviceTypeId"}
                            element={
                                <div className="slds-col slds-size--1-of-1">
                                    <DeviceTypeParsedData />
                                </div>
                            }
                        />
                    </Route>
                    <Route path={"devices"} element={<InitialDevicesPage />} />
                    <Route path={"devices/import"} element={<ImportHardwarePage />} />
                    <Route path={"devices/import/:devTypeId"} element={<ImportHardwarePage />} />
                    <Route
                        path={"devices/type"}
                        element={
                            <ErrorBoundary>
                                <DevicesPage />
                            </ErrorBoundary>
                        }
                    />
                    <Route path={"devices/type/:devTypeId"} element={<DevicesPage />} />
                    <Route path={"devices/:deviceId"} element={<DeviceDetailPage />}>
                        <Route index path={"overview"} element={<DeviceOverview />} />
                        <Route path={"device-data"} element={<DeviceParsedData />} />
                        <Route path={"raw-data"} element={<DeviceSensorData />} />
                        <Route path={"downlinks"} element={<DeviceDownlinkData />} />
                        <Route path={"settings"} element={<DeviceSettings />} />
                        <Route path={"security"} element={<DeviceSecurity />} />
                        <Route path={"config"} element={<DeviceConfig />} />
                    </Route>
                    <Route path={"integrations"} element={<IntegrationsPage />}>
                        <Route index element={<Navigate replace={true} to={"rest"} />} />
                        <Route path={"rest"} element={<RestApiTab />} />
                        <Route path={"filter"} element={<InputFilterTab />} />
                        <Route path={"http"} element={<HttpApiTab />} />
                        <Route path={"mqtt"} element={<MqttApiTab />} />
                        <Route path={"sftp"} element={<SftpApiTab />} />
                        <Route path={"lorawan"} element={<LorawanApiTab />} />
                    </Route>
                    <Route path={"config"} element={<OrgConfigPage />}>
                        <Route index element={<Navigate replace={true} to={"settings"} />} />
                        <Route path={"settings"} element={<OrganisationSettingsPage />} />
                        <Route path={"wmbus/import"} element={<KeyImportPage />} />
                        {/*<Route path={"wmbus/importNew"} element={<KeyImportPage />} />*/}
                        {/*<Route path={"wmbus/keyexchange"} element={<KeyExchangePlatformPage/>}/>*/}
                        <Route path={"wmbus"} element={<WmbusConfigPage />} />
                        <Route path={"users"} element={<OrganisationUsersPage />} />
                        <Route path={"users/:id"} element={<UserDetailPage />} />
                        <Route path={"suborganisations"} element={<SubOrganisationPage />} />
                        <Route path={"api-stats"} element={<OrgApiStatisticsPage />} />
                    </Route>
                </Route>
                <Route path={"/deviceTypes"} element={<DeviceTypesPage />} />
                <Route path={"/deviceTypes/import"} element={<ImportDeviceTypes />} />
                <Route path={"/deviceTypes/:id"} element={<DeviceTypesDetailPage />}>
                    <Route index element={<Navigate replace={true} to={"overview"} />} />
                    <Route path={"overview"} element={<UpdateDeviceTypeDialog />} />
                    <Route path={"parser"} element={<DeviceTypeParserForm />} />
                    <Route path={"deviceTableConfig"} element={<DeviceTableConfigTab />} />
                    <Route path={"dataTableConfig"} element={<DataTableConfigTab />} />
                </Route>
                <Route path={"/configuration"} element={<ConfigurationPage />}>
                    <Route index element={<Navigate replace={true} to={licenseCfg.validateFeatures("lobaro-device-gateway") ? "hardware" : "users"} />} />
                    <Route path={"applications"} element={<AppsPage />} />
                    <Route path={"applications/new"} element={<NewAppPage />} />
                    <Route path={"applications/:id"} element={<AppDetailPage />} />
                    <Route path={"hardware"} element={<HardwareListPage />} />
                    <Route path={"hardware/import"} element={<ImportHardwarePage />} />
                    <Route path={"hardware/:id"} element={<HardwareDetailPage />} />
                    <Route path={"hardwareBattery"} element={<HardwareBatteryPage />} />
                    <Route path={"firmware"} element={<FirmwareManagmentPage />} />
                    <Route path={"activationGroups"} element={<ActivationGroupsListPage />} />
                    <Route path={"activationGroups/:id"} element={<ActivationGroupDetailPage />} />
                    <Route path={"users"} element={<UsersListPage />} />
                    <Route path={"users/:id"} element={<UserDetailPage />} />
                    <Route path={"organisations"} element={<OrganisationsListPage />} />
                    <Route path={"organisations/:id"} element={<OrganisationDetailPage />} />
                    <Route path={"cronLog"} element={<CronLogPage />} />
                    <Route path={"admin"} element={<AdminPage />} />
                    <Route path={"token"} element={<AdminTokenPage />} />
                    <Route path={"license"} element={<LicensePage />} />
                    <Route path={"api-stats"} element={<ApiStatisticsPage />}></Route>
                </Route>
                {frontendConfig?.hardwareActivationEnabled && (
                    <Route path={"/hardwareActivation/"} element={<HardwareActivationPage />}>
                        <Route path={"org/:orgId"} element={<HardwareActivationTable />} />
                        <Route path={"org"} element={<Navigate replace={true} to={auth.organisationId()?.toString()} />} />
                        <Route path={":activationCode"} element={<HardwareActivationTable />} />
                        <Route
                            index
                            element={
                                <>
                                    <div className="slds-text-heading--large slds-m-top--large slds-m-bottom--small">
                                        {t("hardware-activation.activate-hardware", "Activate Hardware")}
                                    </div>
                                    <p>{t("hardware-activation.explanation", "Enter your activation code here. You can find it on the delivery note.")}</p>
                                </>
                            }
                        />
                    </Route>
                )}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
}

const ToolsTopNavigationDropdown = () => {
    const auth = useAuthContext()
    let t = useT()
    const frontendConfig = useContext(FrontendConfigContext)
    const license = useFeatureContext()

    const topNavigationDropdownLinks = []
    if (license.validateFeatures("wmbus-api") && (auth.isLoggedIn() || frontendConfig?.wmbusParserApiPublic)) {
        topNavigationDropdownLinks.push(<TopNavigationDropdownLink to={"/wmbus"} label={t("nav.wmbus-parser-api", "wMbus Parser API")} />)
    }
    if (frontendConfig?.hardwareActivationEnabled && license.validateFeatures("lobaro-device-gateway")) {
        topNavigationDropdownLinks.push(<TopNavigationDropdownLink to={"/hardwareActivation"} label={t("nav.hardware-activation", "Hardware Activation")} />)
    }

    if (topNavigationDropdownLinks.length === 0) {
        return null
    }
    return (
        <TopNavigationDropdown label={t("nav.tools", "Tools")}>
            {topNavigationDropdownLinks.map((elem, i) => {
                return <div key={i}>{elem}</div>
            })}
        </TopNavigationDropdown>
    )
}

export default NonAppModule
